.card .revenue-table table th,
.card .revenue-table td {
    padding-left: 10px;
    padding-right: 10px;
}

.revenue-table tbody tr:last-child {
    font-weight: bold;
}
.annual-total {
    padding: 2px;
    border: 1px solid #E9ECEF;
    float: right;
    font-weight: bold;
    text-align: right;
    width: 100%;
    background: #f2f2f2;
    border-top: 1px solid #aeb9b9;
}