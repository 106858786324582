/* Buttons Css */
.btn-accept {
    background-color: #5499C7 !important;
    border-color: #5499C7 !important;
    color: #ffffff !important;
}

.btn-accept:hover,
.btn-accept:active {
    background-color: #5499C7 !important;
    border-color: #5499C7 !important;
    color: #ffffff !important;
}

.btn-outline-accept {
    color: #5499C7 !important;
    background-color: transparent;
    background-image: none;
    border-color: #5499C7 !important;
}

.btn-outline-accept:hover {
    color: #fff !important;
    background-color: #5499C7 !important;
    border-color: #5499C7 !important;
}



.btn-yellow {
    background-color: #d9b44e !important;
    border-color: #d9b44e !important;
    color: #ffffff !important;
}

.btn-yellow:hover,
.btn-yellow:active {
    background-color: #d9b44e !important;
    border-color: #d9b44e !important;
    color: #ffffff !important;
}

.btn-outline-yellow {
    color: #d9b44e !important;
    background-color: transparent;
    background-image: none;
    border-color: #d9b44e !important;
}

.btn-outline-yellow:hover {
    color: #fff !important;
    background-color: #d9b44e !important;
    border-color: #d9b44e !important;
}



.btn-grey {
    background-color: #9daec4 !important;
    border-color: #9daec4 !important;
    color: #ffffff !important;
}

.btn-grey:hover,
.btn-grey:active {
    background-color: #9daec4 !important;
    border-color: #9daec4 !important;
    color: #ffffff !important;
}

.btn-outline-grey {
    color: #9daec4 !important;
    background-color: transparent;
    background-image: none;
    border-color: #9daec4 !important;
}

.btn-outline-grey:hover {
    color: #fff !important;
    background-color: #9daec4 !important;
    border-color: #9daec4 !important;
}



.btn-value-add {
    background: #f8c200 url(../img/hammer.svg) no-repeat;
    /* background-color: #9daec4; */
    /* border-color: #9daec4; */
    color: #ffffff;
}

.btn-value-add:hover {
    /* background-color: #9daec4 !important; */
    /* border-color: #9daec4 !important; */
    /* color: #ffffff !important; */
}

.btn-outline-value-add {
    background: #f8c200 url(../img/hammer.svg) no-repeat;
    color: #9daec4 !important;
    /* background-color: transparent; */
    /* background-image: none; */
    border-color: #9daec4 !important;
}

/* .btn-sm {
    border-radius: 0.200rem !important;
} */



/* End: Buttons Css */