.master-files-list .copies-label {
    margin-top: 4px;
    margin-right: -20px;
}

.master-files-list .table-striped td {
    padding-right: 0;
}

.master-files-list .table-header-wrapper .table th {
    padding-right: 0;
}