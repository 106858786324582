//
// Navbar search
//

.navbar-search {
    .input-group {
        border-radius: $navbar-search-border-radius;
        border: $navbar-search-border-width solid;
        background-color: transparent;

        .input-group-text {
            background-color: transparent;
            padding-left: 1rem;
        }
    }

    .form-control {
        width: $navbar-search-width;
        background-color: transparent;
    }
}

.navbar-search-dark {
    .input-group {
        border-color: $navbar-search-dark-border-color;
    }

    .input-group-text {
        color: $navbar-search-dark-color;
    }

    .form-control {
        color: $navbar-search-dark-focus-color;

        &::placeholder {
            color: $navbar-search-dark-color;
        }
    }

    .focused {
        .input-group {
            border-color: $navbar-search-dark-focus-border-color;
        }
    }
}

@media screen and (max-width: 480px) {
    .navbar-search-result-dark {
        margin: 0px !important;
        margin-bottom: 10px !important;
    }
}

.client-search-bar {
    margin-left: 35% !important;
}
.navbar-search-result-dark {
    margin-left: 25%;
    margin-bottom: 10px;

    .input-group {
        border-color: #525f7f;
    }

    .input-group-text {
        color: #525f7f;
    }

    .form-control {
        color: black;

        &::placeholder {
            color: $navbar-search-dark-color;
        }
    }

    .focused {
        .input-group {
            border-color: black;
        }
    }
}
.navbar-search-light {
    .input-group {
        border-color: $navbar-search-light-border-color;
    }

    .input-group-text {
        color: $navbar-search-light-color;
    }

    .form-control {
        color: $navbar-search-light-focus-color;

        &::placeholder {
            color: $navbar-search-light-color;
        }
    }

    .focused {
        .input-group {
            border-color: $navbar-search-light-focus-border-color;
        }
    }
}
