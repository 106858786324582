.custom-map-controls {

	.map-marker-label {
		background-color: #000;
		color: #fff !important;
		width: 40px;
		border-radius: 4px;
		margin-top: -30px;
		font-size: 10px !important;
		padding: 2px;
	}

	.comp-marker-negative-location {
		background-color: #d751a3;
	}

	.comp-marker-positive-location {
		background-color: green;
	}

	.comp-marker-excluded-comps {
		background-color: #808080;
	}

	.map-link h4 {
		text-decoration: underline;
		color: #ffa500;
	}

	.gm-style-mtc {
		width: 40px !important;
	}

	.gm-style-mtc button {
		font-size: 10px !important;
		width: 45px !important;
		height: 26px !important;
		padding: 0px !important;
	}

	.gm-svpc img {
		height: 20px !important;
		width: 10px !important;
	}

	.gm-control-active>img:nth-child(1),
	.gm-control-active>img:nth-child(2) {
		width: 10px !important;
	}

	button.gm-control-active {
		font-size: 10px !important;
		width: 25px !important;
		height: 26px !important;
		padding: 0px !important;
		padding: 2px !important;
	}

	.gm-svpc {
		width: 25px !important;
	}

	.gmnoprint>div:last-child {
		height: auto !important;
		width: 25px !important;

	}

	// .gmnoprint {
	//     left: 0 !important;
	// }

	.gm-bundled-control {
		left: 0 !important;
	}

	.gm-style-cc {
		display: none !important;
	}

	.gmnoprint button+div {
		display: none !important;
	}

	.gm-style .gm-style-mtc ul,
	.gm-style .gm-style-mtc li {
		font-size: 12px !important;
		line-height: 0px !important;
		top: 30px !important;
	}

	.gm-style-iw-chr {
		button {
			height: 25px !important;
			width: 35px !important;
		}

		span {
			margin: 5px !important;
		}
	}

	.map-info-window {
		// min-width: 250px;
		display: flex;

		.thumbnail-div {
			padding: 0px 5px 0px 0px;
		}
	}
}