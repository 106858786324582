.react-confirm-alert-body {
    min-width: 450px;
    width: 100%;
}

.release-hold .selected-file-list {
    word-break: break-all;
}

span.handle-pdf-sm-btn .btn-sm {
    margin-left: 4px;
}

.handle-pdf-sm-btn:last-of-type {
    margin-right: 4px;
}

.change-report-type .selected {
    background: #11c2ef !important;
    color: #fff !important;
}

.change-report-type .table td {
    font-size: 14px;
}