.custom-span-text {
    cursor: pointer !important;
}

.react-confirm-alert-body {
    min-width: 450px;
    width: 100%;
}

.release-hold .selected-file-list {
    word-break: break-all;
}

.stripe-msg-label {
    float: left;
    width: 100%;
}

.stripe-buttons .btn-check {
    opacity: 0;
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.btn-check.selected+.btn-outline-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.stripe-buttons label {
    color: #11c2ef;
    background-color: #fff;
    border-color: #11c2ef;
    font-size: 12px;
    border-radius: 4px !important;
    padding: 6px 10px;
}

.stripe-buttons .btn-check.selected+.btn-outline-info {
    color: #fff;
    background-color: #11c2ef;
    border-color: #11c2ef;
    font-size: 12px;
    border-radius: 4px !important;
    padding: 6px 10px;
}

.label-font {
    font-size: 16px;
}

.invalid-feedback {
    display: flex !important;
    text-align: left;
}

span.handle-pdf-sm-btn .btn-sm {
    margin-left: 4px;
}

.handle-pdf-sm-btn:last-of-type {
    margin-right: 4px;
}

.budget-section {
    form h1 {
        font-size: 16px;
    }

    .react-bs-table {
        overflow: hidden;
        display: contents;
    }

    .budget-data {
        button {
            height: 24px;
        }

        i {
            font-size: 12px;
        }

        button i:hover {
            color: #fff;
        }
    }

    input.form-check-input {
        padding: 0;
        margin: -10px;
        top: 0;
        position: absolute;
        right: 12px;
    }

    .form-control {
        background: transparent !important;
    }

    .custom-checkbox {
        margin-bottom: -5px !important;
    }

    .form-group {
        margin-bottom: -5px !important;
    }

    .total-budget-td {
        vertical-align: middle;
    }


    input,
    select {
        height: 30px;
    }

    label {
        font-size: 14px;
        font-weight: bold;
        padding: 6px;
        text-align: left;
        width: 100%;
    }

    .arv_data {
        label {
            padding: 0px;
        }

        span {
            font-size: 16px;
            color: #05a8c6;
        }
    }

    .budget-exhibit {

        input,
        select {
            height: 15px;
        }
    }
}

.others-column-body .grid-image {
    max-height: 400px !important;
    height: auto !important;
    overflow-y: auto;
}

.inspection-draw-items-modal .color-red {
    border: 2px solid #eb0b0b;
}

.inspection-draw-items-modal table td {
    text-align: center;
    vertical-align: middle;
}

.inspection-draw-items-modal table td .form-group {
    margin-bottom: 0px;
}

.modal-draw-inspection-form .margin-top-first-row {
    margin-top: -10px;
}

.release-hold-modal .drop-zone-area label {
    font-weight: unset;
}


.property-comp-modal-summary .modal-footer {
    justify-content: center;
}

.property-comp-modal-summary .modal-header .circular-span {
    height: 31px;
    line-height: 31px !important;
    width: 50px;
}