.line-chart-analysis .recharts-wrapper .recharts-legend-wrapper {
    top: -18px !important;
}

.analysis-compration .table th,
.analysis-compration .table td,
.discrepancy-values-popup td,
.discrepancy-values-popup th {
    line-height: 25px;
}

.discrepancy-comps {
    max-width: 1200px;
    ;
}

.line-chart-msg {
    position: relative;
    left: 0px;
    top: 8px;
    color: green;
    float: left;
}

.analysis-compration .discrepancy-column-body .table td {
    padding: 7px;
}

.condition-rating-modal .comp-column-body,
.others-column-body,
.discrepancy-column-body {
    border: none;
}

.condition-rating-modal .modal-body {
    height: 710px;
    max-height: 710px;
    overflow-y: auto;
}

/* .discrepancy-comps .table td, .discrepancy-comps .table th{
    padding: 7px;
} */

/* Tab  */
.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #21b7ff61 !important;
    font-size: 14px !important;
    padding-left: 20px !important;
}

.react-tabs__tab {
    font-size: 14px;
}

ul.sub-tab-list {
    border-bottom: 1px solid #21b7ff61 !important;
    font-size: 14px !important;
    padding-left: 20px !important;
}

.react-tabs__tab--selected {
    border: 2px solid #21b7ff61;
    border-radius: 3px;
    display: inline-block;
    border-bottom: none;
}

.order-management .table-responsive {
    overflow-y: visible;
    overflow-x: visible;
}

.react-bs-table {
    width: 100%;
    overflow: auto;
}

.comp-list .table th {
    background: #1f3864;
    color: #fff;
}

.comp-list .table td,
.comp-list .table th {
    padding: 4px 3px;
    text-align: center;
}

.myMapDiv {
    height: 350px;
    margin-bottom: 20px;
}

.commentary-label {
    font-weight: bold;
    text-decoration: underline;
}

.comp-list .table td:nth-child(2),
.comp-list .table th:nth-child(2) {
    width: 15%;
}

.comp-list .table td:nth-child(1),
.comp-list .table th:nth-child(1) {
    width: 3%;
}

.comp-list .table td:nth-child(3),
.comp-list .table th:nth-child(3) {
    width: 2%;
}

.comp-list .table td:nth-child(4),
.comp-list .table th:nth-child(4) {
    width: 5%;
}

.comp-list .table td:nth-child(5),
.comp-list .table th:nth-child(5) {
    width: 5%;
}

.comp-list .table td:nth-child(6),
.comp-list .table th:nth-child(6) {
    width: 5%;
}

.comp-list .table td:nth-child(7),
.comp-list .table th:nth-child(7) {
    width: 5%;
}

.comp-list .table td:nth-child(8),
.comp-list .table th:nth-child(8) {
    width: 5%;
}

.comp-list .table td:nth-child(9),
.comp-list .table th:nth-child(9) {
    width: 5%;
}

.comp-list .table td:nth-child(10),
.comp-list .table th:nth-child(10) {
    width: 5%;
}

.comp-list .table td:nth-child(11),
.comp-list .table th:nth-child(11) {
    width: 5%;
}

.comp-list .table td:nth-child(12),
.comp-list .table th:nth-child(12) {
    width: 5%;
}

.comp-list .table td:nth-child(13),
.comp-list .table th:nth-child(13) {
    width: 5%;
}

.comp-list .table td:nth-child(14),
.comp-list .table th:nth-child(14) {
    width: 5%;
}

.comp-list .table td:nth-child(15),
.comp-list .table th:nth-child(15) {
    width: 5%;
}

.comp-list .table td:nth-child(16),
.comp-list .table th:nth-child(16) {
    width: 5%;
}

.comp-list .table td:nth-child(17),
.comp-list .table th:nth-child(17) {
    width: 5%;
}

.myMapDiv .map-link p {
    margin-bottom: 2px;
}

h2.not-authorized-msg {
    text-align: center;
    padding: 10px;
    opacity: 0.5;
}

.custom-link {
    cursor: pointer;
}

.overview-row .chart-col {
    padding-left: 0px;
    padding-right: 0px;
}

.overview-row .chart-col .card-body {
    padding-left: 0px;
    padding-right: 0px;
}

.overview-row .report-col .card-body {
    padding-left: 0px;
    padding-right: 0px;
}

/* .recharts-cartesian-axis-tick-value { font-size: 12px;} */
.overview-row .report-col .card-body .col-lg-6.col-xl-6 {
    padding-left: 0px;
    padding-right: 0px;
}

.overview-row .w-35 {
    width: 35%;
}

.overview-row .w-25 {
    width: 25%;
}

.overview-row .w-40 {
    width: 40%;
}

.overview-column-chart {
    position: absolute;
    top: 52px
}

.chart-heading {
    position: absolute;
    /* top: 9%; */
    left: 40%;
}

.chart-heading-arv {
    position: absolute;
    top: 16%;
    left: 40%;
}

.column-chart-analysis .recharts-responsive-container {
    position: relative;
    top: 22px;
}

.progress-bar {
    height: 30px;
    width: 100%
}

.progress-bar-label span {
    width: 20%;
    float: left;
}

.progress-bar-label span:last-child {
    margin-right: -20px;
}

.lastProgress {
    margin-left: -4px;
    ;
}

.progress-bar-a-plus .bg-success {
    background-color: #00B050 !important;
    color: #fff;
    font-weight: bold;
}

.progress-bar-a .bg-success {
    background-color: #00B050 !important;
    color: #fff;
    font-weight: bold;
}

.progress-bar-a-minus .bg-success {
    background-color: #5EE487 !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-b-plus .bg-success {
    background-color: #B7F3CA !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-b .bg-success {
    background-color: #B0F0FF !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-b-minus .bg-success {
    background-color: #B0F0FF !important;
    color: #000;
    font-weight: bold;
}


.progress-bar-c-plus .bg-success {
    background-color: #FDF7BB !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-c .bg-success {
    background-color: #FBEE66 !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-c-minus .bg-success {
    background-color: #F0DA06 !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-d-plus .bg-success {
    background-color: #FFD333 !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-d .bg-success {
    background-color: #FEB96E !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-d-minus .bg-success {
    background-color: #FD931F !important;
    color: #000;
    font-weight: bold;
}


.progress-bar-e-plus .bg-success {
    background-color: #F3BFE0 !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-e .bg-success {
    background-color: #EB95CC !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-e-minus .bg-success {
    background-color: #DC44A5 !important;
    color: #000;
    font-weight: bold;
}


.progress-bar-f .bg-success {
    background-color: #C00000 !important;
    color: #000;
    font-weight: bold;
}

.progress-bar-d .bg-success {
    background-color: #FEB96E !important;
    color: #000;
    font-weight: bold;
}

.category-waived {
    float: right;
    margin-right: 48px;
}

.filter-buttons .bg-green {
    color: #fff;
    background-color: #808080 !important;
}

.filter-buttons .bg-yellow {
    color: #fff;
    background-color: #808080 !important;
}

.filter-buttons .bg-blue {
    color: #fff;
    background-color: #808080 !important;
}

.filter-buttons .bg-green.active {
    background-color: #008000 !important;
    color: #fff;
}

.filter-buttons .bg-yellow.active {
    background-color: #ffd600 !important;
    color: #fff;
}

.filter-buttons .bg-blue.active {
    background-color: #3D3DDF !important;
    color: #fff;
}

.span-yellow {
    background-color: #ffd600;
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
}

.span-blue {
    background-color: #3D3DDF;
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
}

.span-agree {
    background-color: #008000;
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
}

.span-red {
    background-color: #DD4B3E;
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
}




.userBanned.category-waived .toggle-switch-inner:before {
    padding-left: 25px;
}

.userBanned.category-waived .toggle-switch-inner:after {
    padding-right: 25px;
}

.subcategory-view {
    max-width: 30%;
}

.value-curve-section table {
    table-layout: fixed;
}

.value-curve-section table.table th {
    padding-left: 5px;
    padding-right: 0px;
}

.value-curve-section table.table td {
    padding-left: 5px;
    padding-right: 5px;
}

.progress-bar span {
    position: absolute;
    left: 42%;
}

.property-detail span.heading-label {
    font-weight: 550;
    font-size: 16px;
}

.filter-buttons .btn-check {
    display: none;
}

table.analysis-table th:not(:first-child),
table.analysis-table td:not(:first-child) {
    text-align: center;
}

.sub-category-chart .recharts-text,
.sub-category-chart .recharts-layer.recharts-bar-rectangle {
    cursor: pointer;
}

.overview-row .form-check-input.inspection-checkbox {
    pointer-events: none;
}

.recharts-layer.recharts-bar {
    width: 300px;
}

.compensation-modal .analysis-table td:first-child span {
    margin-top: 4px;
    display: flex;
}

.analysis-compration .overview-row .col-lg-7 .table .circle {
    padding: 0;
    text-align: center;
}

.circle {
    width: 34px;
    height: 26px;
    background-color: #3498db;
    border-radius: 50%;
    padding: 4px;
    color: #fff;
    display: inline-block;
}