.custom-label {
    display: inline-flex;
    margin-bottom: 0;
    width: 100%;
}

/* .custom-checkbox {
    width: 100%;
    text-align: right;
} */

.custom-label .custom-control-label {
    font-size: 14px !important;
    padding-left: 30px !important;
}

.custom-label .custom-control-label::before {
    left: 0px;
}

.custom-label .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    left: 0px !important;
}

.react-datepicker__triangle {
    margin-bottom: -7px !important;
    margin-left: -10px !important;
}

.month-picker .react-datepicker-wrapper {
    width: 100% !important;
}

.order-intake-order-information .react-datepicker-wrapper {
    width: 80% !important;
}

.email-group {
    box-shadow: none;
}

.email-group {
    width: 100%;
}

.email-group .input-group-prepend {
    display: block !important;
}

.email-group input {
    width: 100% !important;
    height: 40px !important;
}

.payment-modal .input-group-prepend .input-group-text,
.custom-tag-input .input-group-prepend .input-group-text {
    display: grid;
    text-align: left;
}

.custom-tag-input .input-group-prepend .tag-item {
    height: auto;
    max-height: 70px;
    word-break: break-all;
    white-space: break-spaces;
    display: flex;
    float: left;
}

.custom-tag-input .tag-item {
    background-color: #d4d5d6;
    width: fit-content;
    font-size: 14px;
    border-radius: 30px;
    height: 24px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    color: #000;
}

.custom-tag-input .tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-tag-input .input-group-alternative {
    border: 1px solid #d1cdcd !important;
}

.common-bootstrap-table td {
    word-break: break-word;
}

.coming-soon-div {
    height: 400px;
}

.coming-soon-div h1 {
    text-align: center;
    font-size: 35px;
}

.common-bootstrap-table span.required:after {
    content: "*";
    color: #c80a0a;
    margin-left: -3px;
}