.drop-zone-area {
    text-align: left;

    label {
        font-weight: bold;
    }

    .dropzone {

        padding: 10px !important;

        .drag-drop-text {
            color: #11c2ef !important;
            font-size: small;
        }

        li {
            margin-left: -20px;
            font-size: 13px;
        }
    }

    .dropzone-min-height-auto {
        min-height: auto;
    }

}

.selected-file-list ul {
    padding: 0px;
}

.selected-file-list ul li {
    margin: 10px 2px;
    font-size: small;
    word-break: break-all;
    list-style: none;
    display: inline-flex;
}

.selected-file-list ul li i {
    cursor: pointer;
    height: 20px;
    margin-top: 3px;
}

.selected-file-list ul li div {
    margin-left: 6px;
}
