/* AV-RADIO */
.custom-av-radio-btn .form-check {
    padding-left: 0px;
}

.custom-av-radio-btn input {
    position: absolute;
    opacity: 0;
}

.custom-av-radio-btn legend {
    font-size: medium;
    margin-bottom: 0px;
}

.custom-av-radio-btn label {
    margin-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 4px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    overflow: auto;
    min-width: 150px;
    font-weight: 600;
    text-align: center;
    /* padding: 6px 10px; */
    padding: 6px;
    cursor: pointer;
    font-size: 12px !important;
    margin-left: -6px;
    /* max-width: 200px; */
}


.custom-av-radio-btn.inline label {
    float: left;
    float: left;
    display: block;
}

.custom-av-radio-btn.block label {
    margin-bottom: 10px;
    margin-top: 5px;
    margin-right: 10px;
    background-color: #EFEFEF;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    overflow: auto;
    float: left;
    float: left;
    min-width: 100%;
    font-weight: 600;
    text-align: center;
    padding: 3px 0px;
    display: block;
    padding: 6px;
    cursor: pointer;
}

.custom-av-radio-btn label:hover {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.custom-av-radio-btn input:checked+label {
    background-color: #11cdef !important;
    color: #fff !important;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    border: 6px solid #11cdef !important;
    background-color: transparent !important;
}

.custom-radio label.custom-control-label {
    padding: 2px !important;
    padding-left: 25px !important;
}

.custom-radio .custom-control-label::before {
    left: 0 !important;
}

.custom-radio {
    padding: 0px 5px !important;
}

.custom-radio .custom-control-label::before {
    left: -2rem;
    pointer-events: all;
}

/* .custom-av-radio-btn label:hover, .custom-av-radio-btn.inline label:hover {background:#5e72e4;color: #fff;} */
/* .custom-radio label.custom-control-label{margin-left: -25px !important;} */

/* CUSTOM- RADIO */
.custom-radio-btn input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.custom-radio-btn.inline div {
    display: inline;
}

.custom-radio-btn label {
    margin-top: 1px;
    margin-right: 10px;
    border-radius: 4px;
    min-width: 150px;
    font-weight: 600;
    text-align: center;
    padding: 6px 15px;
    cursor: pointer;
    font-size: 12px !important;
    width: 100%;
    max-width: 100%;

    color: #8c9cad;
    background-color: #d0d0d008;
    border: 1px solid #D0D0D0;

    text-transform: none;
    transition: all 0.15s ease;
    will-change: transform;
}

.custom-radio-btn label:hover {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.custom-radio-btn.inline label {
    width: auto !important;
    max-width: 100%;
}

.radio-w-50-p label {
    width: auto !important;
    min-width: 45%;
}

.custom-radio-btn input[type="radio"]:checked+label {
    background-color: #11cdef;
    color: #fff;
}