.inspection-draw-items-modal .color-red {
    border: 2px solid #eb0b0b;
}

.inspection-draw-items-modal table td {
    text-align: center;
    vertical-align: middle;
}

.inspection-draw-items-modal table td .form-group {
    margin-bottom: 0px;
}

.inspection-intake-draw-item-lists .line-item-categories .form-control {
    padding: 3px;
}

.inspection-intake-draw-item-lists .line-items-description .form-control,
.inspection-intake-draw-item-lists .line-items-cost .form-control,
.inspection-intake-draw-item-lists .line-items-new-complition-percent .form-control {
    padding-left: 5px !important;
    height: 28px;
}

.inspection-intake-draw-item-lists td {
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.inspection-intake-draw-item-lists .rbt-input-multi.form-control {
    max-height: 100px;
    min-height: 30px;
}

.inspection-intake-draw-item-lists .react-tabs__tab-panel {
    padding: 0px;
}

.inspection-order-intake .selection-section .form-group {
    margin-bottom: 0px;
}

.inspection-order-intake .inspection-intake-form-tab-container {
    max-height: none;
    overflow: unset;
}

.inspection-order-intake .inspection-intake-form-tab-container .table-container {
    max-height: 300px;
    overflow-y: auto;
}
.manage-draw-line-item .full-height {
    max-height: 800px;
    overflow: auto;
}

.modal-draw-inspection-form .draw-height-table {
    max-height: 500px;
    overflow: auto;
}
.card .table td,
.card .table th {
    padding-left: 5px;
    padding-right: 5px;
}
.inspection-draw-items-modal .custom-checkbox-container .draw-line-checkbox {
    padding-left: 0px;
    padding-right: 20px;
}