.custom-conversation,
.custom-conversation a {
    color: #00008B;
    font-weight: 700;
}

.message-list {
    padding: 0 15px 0 15px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.input-message-box {
    border: solid 1px gray;
    padding: 4px;
}

.right-chat-message-detail {
    float: right;
    position: relative;
    background: #ADD8E6;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    width: auto;
    color: #0C2D48;
    margin-bottom: 10px;
    font-size: 14px;
}

.right-chat-box-arrow_client {
    float: right;
    position: relative;
    background: #ADD8E6;
    border-radius: 5px;
    border: solid 4px #0C989A;
    padding: 5px 10px 5px 10px;
    width: auto;
    color: #0C2D48;
    margin-bottom: 1px;
    font-size: 14px;
}

.right-chat-box-arrow_client:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 8px solid;
    border-color: transparent transparent transparent #0C989A;
    right: -19px;
    top: 2px;
}

.right-chat-box-arrow_internal {
    float: right;
    position: relative;
    background: #ADD8E6;
    border-radius: 5px;
    border: solid 4px #5e72e4;
    padding: 5px 10px 5px 10px;
    width: auto;
    color: #0C2D48;
    margin-bottom: 1px;
    font-size: 14px;
}

.right-chat-box-arrow_internal:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 8px solid;
    border-color: transparent transparent transparent #5e72e4;
    right: -19px;
    top: 2px;
}

.right-chat-box-arrow_inspector {
    float: right;
    position: relative;
    background: #ADD8E6;
    border-radius: 5px;
    border: solid 4px #fb6340;
    padding: 5px 10px 5px 10px;
    width: auto;
    color: #0C2D48;
    margin-bottom: 1px;
    font-size: 14px;
}

.right-chat-box-arrow_inspector:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 8px solid;
    border-color: transparent transparent transparent #fb6340;
    right: -19px;
    top: 2px;
}

.left-chat-box-arrow_client {
    position: relative;
    background: #D3D3D3;
    border-radius: 5px;
    border: solid 4px #0C989A;
    padding: 5px 10px 5px 10px;
    width: auto;
    color: black;
    margin-bottom: 1px;
    font-size: 14px;
}

.left-chat-box-arrow_client:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 8px solid;
    border-color: transparent #0C989A transparent transparent;
    left: -19px;
    top: 2px;
}

.left-chat-box-arrow_internal {
    position: relative;
    background: #D3D3D3;
    border-radius: 5px;
    border: solid 4px #5e72e4;
    padding: 5px 10px 5px 10px;
    width: auto;
    color: black;
    margin-bottom: 1px;
    font-size: 14px;
}

.left-chat-box-arrow_internal:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 8px solid;
    border-color: transparent #5e72e4 transparent transparent;
    left: -19px;
    top: 2px;
}

.left-chat-box-arrow_inspector {
    position: relative;
    background: #D3D3D3;
    border-radius: 5px;
    border: solid 4px #fb6340;
    padding: 5px 10px 5px 10px;
    width: auto;
    color: black;
    margin-bottom: 1px;
    font-size: 14px;
}

.left-chat-box-arrow_inspector:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 8px solid;
    border-color: transparent #fb6340 transparent transparent;
    left: -19px;
    top: 2px;
}

.message-type-client {
    font-weight: bold;
    text-decoration: underline;
    color: #0C989A;
}

.message-type-internal {
    font-weight: bold;
    text-decoration: underline;
    color: #5e72e4;
}

.message-type-inspector {
    font-weight: bold;
    text-decoration: underline;
    color: #fb6340;
}

.accept-file-message {
    color: gray;
    font-weight: bold;
    font-size: small;
}

.notification_span {
    position: relative;
}

.notification {
    position: absolute;
    right: -0.7em;
    top: -1.6em;
    min-width: 1.6em;
    height: 1.6em;
    border-radius: 0.8em;
    background-color: #0C2D48;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0em;
    color: white;
}

