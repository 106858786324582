.discrepancy-property-model {
    .flex-column {
        max-width: 260px;
    }

    .scale {
        transform: scaleY(1.05);
        padding-top: 5px;
    }

    .img-fluid {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }

    .img-area {
        padding: 5px;
        height: 100px;
    }

    .img-area img {
        object-fit: cover;
        height: 100%;
    }

    .grid-image {
        max-height: 500px;
        height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 5px;
    }

}