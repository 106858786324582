button:disabled img {
    opacity: 0.5;
    filter: grayscale(30%);
}

div::-webkit-scrollbar,
nav::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

div::-webkit-scrollbar-track,
nav::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #c9cff1;
}

div::-webkit-scrollbar-thumb,
nav::-webkit-scrollbar-thumb {
    background-color: #0ae;
    outline: 1px solid #8bacf1;
}

p {
    font-size: 14px;
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px !important;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #cad1d7;
    border-radius: 5px;
    line-height: 25px;
    height: 30px !important;
    width: 100% !important;
    outline: none;
}

h1,
h2,
h3,
h4,
h5 {
    color: #747d92;
}

a {
    color: currentColor;
}

.table {
    margin-bottom: 0;
}

body {
    font-size: 14px !important;
}

/* Table */
.table-responsive .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: -2px;
    background-color: white;
    z-index: 3;
}

.table-responsive .scroll {
    overflow-y: auto;
    max-height: 300px;
}

/* Login */
.custom-link {
    color: #2ea4be;
}

.navbar-horizontal .navbar-brand img {
    height: 60px;
}

img.white-logo {
    width: 300px;
}

.reset .col-4 {
    padding-right: 5px !important;
}

.input-group-prepend {
    margin-right: 0px;
}

.form-control {
    padding-left: 5px !important;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 14px !important;
    color: #f5365c;
    left: 0;
}

.input-group input {
    border-radius: 10px !important;
}

/* Center Spinner */
.loading {
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.7;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
    background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.dropzone {
    -webkit-flex: 1 1;
    flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    padding: 25px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #aeaeae;
    border-style: dashed;
    background-color: #fafafa;
    color: #5a5151;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone-draw-inspection-complete {
    -webkit-flex: 1 1;
    flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    padding: 5px;
    font-size: small;
    border-width: 2px;
    border-radius: 2px;
    border-color: #aeaeae;
    border-style: dashed;
    background-color: #fafafa;
    color: #5a5151;
    outline: none;
    transition: border .24s ease-in-out;
}

.order-listing-table .table th {
    line-height: unset !important;
}

/* React confirm box */
body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #959191e6;
    z-index: 9999;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 450px;
    padding: 20px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body>h1 {
    margin-top: 0;
}

.react-confirm-alert-body>h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.form-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 14px !important;
    color: #f5365c;
    left: 0;
    text-align: left;
}

.error {
    color: #f5365c;
}

.container-vh-100 {
    min-height: 80vh;
}

.public-footer {
    padding: 1.0rem 0px 0.5rem 0px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer {
    padding: 1.0rem 0px 0.5rem 0px;
}

ul.sub-menu {
    list-style: none;
}

ul.sub-menu .nav-link.active:before {
    border-left: none !important;
}

ul.sub-menu .nav-link {
    border-left: 2px solid rgba(167, 165, 165, 0.5) !important;
}

.difference {
    color: orange !important;
    font-weight: 600;
}

.tooltip-inner {
    text-align: left;
    font-size: 12px;
}

.clear-search {
    margin-top: 8px;
    margin-right: 8px !important;
    cursor: pointer;
    background: #fff;
    font-weight: 600;
    color: #4a64f5;
    height: 26px;
    width: 60px;
    text-align: center;
    border-radius: 14px;
    font-size: 14px;
    padding: 2px;
}

.react-bs-table-pagination {
    text-align: left;
}

.hide {
    display: none !important;
}

.no-result-body.row h2 {
    text-align: center;
    width: 100%;
    color: #b8def0;
    margin-top: 60px;
    font-size: 30px;
    margin-bottom: 200px;
}

div.tooltip-inner {
    max-width: 300px;
    padding: 0.25rem 0.5rem;
    color: #000;
    background-color: #b6c9e7;
    border-radius: 0.375rem;
    white-space: pre-line;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #b6c9e7;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #b6c9e7;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #b6c9e7;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-right-color: #b6c9e7;
}

.react-bs-table-sizePerPage-dropdown li a {
    padding-right: 100%;
}

.compensation-table td {
    padding: 7px !important;
}

td.react-bs-table-no-data {
    text-align: center;
}

table button {
    padding: 6px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    margin-right: 1px !important;
}

.table-responsive {
    width: 100% !important;
    overflow-y: visible;
    overflow-x: visible;
}

label span.required {
    color: rgb(200, 10, 10);
}

.custom-button {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
    padding-top: 5px;
    padding-bottom: 5px;
}

.table td,
.table th {
    white-space: break-spaces;
}

.table th,
.table td {
    padding: 5px;
}

label.required:after {
    content: "*";
    color: rgb(200, 10, 10);
}

.table td {
    white-space: break-spaces !important;
    font-size: 12px;
}

/* Discrepancy Tabs Component Modal */
.discrepancy-property-model {
    padding: 15px;
}

.discrepancy-property-model .modal-body {
    height: 650px;
    overflow: auto;
}

.discrepancy-property-model hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.comp-column-body,
.others-column-body,
.discrepancy-column-body {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 89%;
    padding: 10px 0px 10px 0px;
}

.discrepancy-property-model .comp-data span {
    font-weight: normal;
}

.property-comp-table label,
.comp-column-body label {
    font-weight: 600;
    font-size: 12px !important;
}

.property-comp-table .thead-light th {
    padding-left: 5px;
}

.comp-column-body {
    text-align: left;
}

.comp-column-body .row {
    margin-bottom: -15px;
}

.difference {
    color: orange !important;
    font-weight: 600;
}

.comp-column-body .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 10px + 2px);
    padding: .625rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: .375rem;
    box-shadow: none;
    transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
}

.others-column-body .carousel .control-dots {
    margin-bottom: 35px !important;
}

.others-column-body .carousel ul.thumbs.animated {
    padding-left: 0;
}

.others-column-body .thumbs-wrapper.axis-vertical {
    margin-left: 0;
    margin-right: 0;
    overflow-x: scroll;
    max-height: 80px;
}

.others-column-body .carousel .thumbs-wrapper.axis-vertical .thumb {
    max-height: 60px;
}

.others-column-body .carousel .carousel-status {
    color: #000 !important;
    font-weight: bold !important;
    text-shadow: none !important;
    background: #fff;
    opacity: 0.5;
    border-radius: 4px;
    margin-top: 5px;
    margin-right: 5px;
}

.others-column-body .carousel .slide img {
    max-height: 500px;
    object-fit: cover;
    width: auto !important;
}

.others-column-body .carousel .axis-vertical .control-arrow {
    background: #000 !important;
    opacity: 0.5;
    top: 30px !important;
}

.others-column-body .react-tabs__tab-panel {
    padding-bottom: 75%;
    position: relative;
    height: 0;
}

.others-site {
    margin-top: 100px;
}

.others-site img {
    padding: 20px;
    width: 200px;
}

.fema-image {
    width: 150px !important;
    height: 100px;
    margin-right: 15px;
}

.map-image,
.regrid-image {
    width: 90px !important;
    height: 90px;
}

.discrepancy-column-body .custom-radio .custom-control-label {
    cursor: pointer !important;
}

.discrepancy-column-body .custom-radio {
    padding: 0 !important;
}

.discrepancy-column-body .custom-control-label::before {
    left: -2rem;
    pointer-events: all;
}

.discrepancy-column-body .custom-control-input:checked~.custom-control-label::before {
    border: 6px solid #5e72e4 !important;
    background-color: transparent !important;
}

/* .Toastify__toast--error {
    background: #b2463b;
}

.Toastify__toast--success {
    background: #2e9431;
} */

.react-tabs__tab-panel {
    padding: 20px;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

button#pageDropDown {
    color: #99a0b3;
    background-color: white;
    border-color: #00000052;
    padding: 7px 12px;
}

.react-bs-table-pagination .page-item.active .page-link {
    background-color: white;
    border-color: #acb2c2;
    color: black;
}

.react-datepicker__navigation {
    margin: 8px;
}

.table th {
    white-space: break-spaces !important;
}

.rbt-token .rbt-token-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 4px 0px !important;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -2px;
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #11cdef !important;
    border-color: #adb5bd !important;
}

.rbt-token {
    background-color: #71899d !important;
    color: #ffffff !important;
}

.rbt-token .close>span:not(.sr-only) {
    color: #ffffff;
}

textarea#question_comment {
    white-space: pre-line;
}

@media (max-width: 768px) {
    .menu-nav-icon {
        display: none;
    }

    .table-responsive {
        width: fit-content;
        overflow: auto !important;
    }

    .react-bs-table {
        width: fit-content !important;
    }

    .navbar-search .form-control {
        width: 100%;
    }

    .navbar-search .form-group {
        margin-bottom: 0 !important;

    }

    .navbar-search .clear-search {
        right: 0;
        position: absolute;
    }
}

span[role="button"]:hover {
    color: #233dd2;
}

.custom-due-rush {
    font-weight: 700;
}

.custom-due-rush,
.custom-due-rush a,
.custom-due-date,
.custom-due-date a {
    color: red;
}

.custom-rush-order,
.custom-rush-order a {
    color: #c66aeb;
}

.table>thead>tr>th.condition-class-8 {
    background: #AB40D7;
}

.table>thead>tr>th.condition-class-7 {
    background: #3D3DDF;
}

.table>thead>tr>th.condition-class-6 {
    background: #50C9D6;
}

.table>thead>tr>th.condition-class-5 {
    background: #74D170;
}

.table>thead>tr>th.condition-class-4 {
    background: #E6E85E;
}

.table>thead>tr>th.condition-class-3 {
    background: #F4AB2C;
}

.table>thead>tr>th.condition-class-2 {
    background: #A63322;
}

.table>thead>tr>th.condition-class-1 {
    background: #575757;
}

.table>thead>tr>th.condition-class-0 {
    background: #22caab;
}

.property-market-place img {
    width: 40px;
    height: 40px;
}

.email-group .form-group {
    width: 100% !important;
}

.svg-icons {
    width: 16px;
}

.custom-control-label::after {
    left: -2rem;
}

.custom-control-label::before {
    left: -2rem;
}

.custom-control {
    min-height: 1.5rem;
    padding-left: 2rem;
}

.common-template-logo {
    display: none;
}

a.nav-link.active {
    background: #12c4ee4d;
}

.main-content .container-fluid,
.main-content .container-sm,
.main-content .container-md,
.main-content .container-lg,
.main-content .container-xl {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.custom-toggle {
    width: 90px;
    height: 30px;
}

.custom-toggle .standard+.custom-toggle-slider:before {
    height: 24px;
    width: 26px;
    right: 28px !important;
    left: 35px !important;
    position: absolute !important;
}

.custom-toggle .custom+span {
    text-align: right;
    padding: 5px !important;
    border: 1px solid #cdd0d4;
    font-weight: 600;
}

.custom-toggle input:checked+.custom-toggle-slider {
    border: 1px solid #11cdef;
}

.custom-toggle input:checked+.custom-toggle-slider:before {
    background: #11cdef !important;

}

.custom-toggle .standard+span {
    text-align: left;
    padding: 5px !important;
    border: 1px solid #11cdef;
    font-weight: 600;

}

.custom-toggle .custom+.custom-toggle-slider:before {
    height: 24px;
    width: 26px;
}

.cursor-pointer {
    cursor: pointer;
}

.text-decoration-underline {
    text-decoration: underline;
}

.selectable-table tr {
    cursor: pointer;
}

.selectable-table .selected {
    background: #11c2ef !important;
    color: #fff !important;

}


.pac-container {
    z-index: 1111;
}

.w-80 {
    width: 80% !important;
}

.text-light-blue {
    color: #11c9ee;
}

.full-screen {
    margin-left: 60px !important;
}

.full-screen-nav {
    width: 60px !important;
}

.menu-nav-icon {
    background: #fff;
    padding: 4px 12px;
    left: -8px;
    position: absolute;
    top: 0px;
    border-radius: 4px;
}

.full-screen-nav .navbar-brand {
    width: 40px;
    left: 0;
    position: absolute;
    top: 0;
}

.full-screen-nav .nav-item a {
    width: max-content;
}

.add-company .scroll {
    overflow-y: auto;
    max-height: 300px
}

.add-company .fixed {
    position: sticky;
    top: -2px;
    background-color: white;
    z-index: 3;
}

.add-company .bg-light-gray {
    background-color: #f2f2f2;
    transition: all 0.3s ease;
}

.add-company .rounded-20 {
    border-radius: 20px;
}

.add-company .z-index-2 {
    z-index: 2;
}

.add-company .z-index-5 {
    z-index: 5 !important;
    cursor: pointer;
}

.add-company .form-control {
    background-color: transparent;
}

.add-company .custom-checkbox .custom-control-input {
    z-index: 1 !important;
    cursor: pointer;
}

.add-company .custom-link:hover {
    color: #233dd2;
    text-decoration: none;
}

.add-company .dropdown-menu {
    z-index: 10;
}

.add-company .w-300px {
    width: 300px;
}

.add-company .custom-right-checkbox .invalid-feedback {
    top: -4px !important;
    position: absolute !important;
    margin-left: 100px;
}

.add-company .form-group {
    margin-bottom: 0 !important;
}

/* Email list css */
.email-list .rdtPicker {
    width: 290px;
}

/* neighborhood-land-user */
.neighborhood-land-user .custom-link {
    color: #fff;
}

.neighborhood-land-user .photo-files {
    width: 100%;
    margin: 15px auto;
}

.form-group-margin-reset .form-group {
    margin-bottom: 0px !important;
}

.neighborhood-land-user .photo-files {
    width: 100%;
    margin: 15px auto;
}

.neighborhood-land-user .underline-heading {
    border-bottom: 1.6px solid #aaaaaa8e;
    margin-left: 5px;
}

.neighborhood-land-user .w-350 {
    width: 350px;
}

.neighborhood-land-user .step-3 .full-view {
    height: calc(100vh - 200px);
}

.neighborhood-land-user .step-3 .pdf-section {
    height: 100%
}

.navbar-vertical .navbar-nav .nav-link .dv-plus,
.navbar-vertical .navbar-nav .nav-link .dv-minus {
    font-size: 1rem;
}

.navbar-vertical .navbar-nav .nav-link>i {
    min-width: 2.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.navbar-vertical .navbar-nav .nav-link>img {
    width: 20px;
    margin-right: 18px;
}

/* Modal CSS */
.modal-header-colored {
    padding: 10px;
    background: linear-gradient(87deg, #11cdef 50%, #1171ef 100%) !important;
}

.modal-header-colored .modal-title,
.modal-header-colored .close>span {
    color: #fff;
    line-height: unset;
}

.modal-header-colored span {
    font-weight: normal;
    line-height: 25px;
    color: #fff;
}

.modal-header-colored .close {
    padding: 1rem;
}

.modal-header-colored .custom-link {
    color: #fff;
    text-decoration: underline;
}

.modal-header-colored i {
    color: #fff;
}

.health-link {
    position: relative;
    bottom: 0;
    left: -18px;
    padding: 20px;
}

@media screen and (min-width: 992px) {
    .modal-xxl {
        max-width: 800px;
    }
}

@media screen and (min-width: 1200px) {
    .modal-xxl {
        max-width: 1250px;
    }
}

@media screen and (min-width: 1300px) {
    .modal-xxl {
        max-width: 1350px;
    }
}

@media screen and (min-width: 1400px) {
    .modal-xxl {
        max-width: 1450px;
    }
}

/* Table Row Border Error */
.table-row-error {
    border: 2px solid #f5365c;
    border-radius: 2px;
    background-color: #ffcdd233;
}

.preserve-format {
    white-space: pre-wrap;
}

/* Automated Reports CSS */
.custom-card-body {
    border-right: 1px solid #11c2ef;
    border-left: 1px solid #11c2ef;
}

.custom-card-header {
    background-color: rgb(216, 229, 236);
}

.custom-header-text {
    color: #232427;
}

.epo-order-intake-order-information .main-custom-text {
    max-height: 250px;
    overflow-y: auto;
}

.div-header-colored {
    padding: 5px;
    background: #0c989a;
    /* background: #adb5bd; */
    /* background: #14b5ed ; */
    color: #fff;
    border-bottom: 0 solid #e9ecef;
    border-top-left-radius: 0.4375rem;
    border-top-right-radius: 0.4375rem;
}