.client-response-popup {
    .custom-control-label::after {
        display: none;
    }
}

.reopen-file-popup {
    .custom-av-radio-btn.inline label {
        font-weight: 600;
        padding: 6px 15px;
        /* cursor: pointer; */
        font-size: 12px;
        /* width: 100%; */
        /* max-width: 100%; */
        color: #8c9cad;
        background-color: #d0d0d008;
    }
}

.discrepancy-comp {
    height: 500px !important;

    .react-tabs__tab-panel {
        position: inherit;
    }

    .react-tabs__tab-panel {
        height: auto !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    .carousel .slide img {
        min-height: 400px !important
    }
}

.documents-upload {
    .modal {
        text-align: left !important;
    }

    .drop-zone-area {
        text-align: left;

        label {
            font-weight: bold;
        }

        .dropzone {

            padding: 10px !important;

            .drag-drop-text {
                color: #11c2ef !important;
                font-size: small;
            }

            li {
                margin-left: -20px;
                font-size: 13px;
            }
        }
    }


}

.uploaded-docs-col {
    max-height: 400px;
    overflow-y: auto;
}

.training-files-group .form-check-inline {
    width: 47%;
}

.training-files-group .form-check-inline .form-check-input {
    height: 35px;
    width: 17px;
}