.dispute-process-modal .dispute-step1-table .invalid-feedback {
    display: none !important;
}

.dispute-process-modal .dispute-step1-table .form-group {
    margin-bottom: 0px;
}

.dispute-process-modal .red-text,
.react-confirm-alert-body .red-text {
    color: red;
    font-weight: bold;
    font-style: italic;
}

.noUi-connect {
    background-color: #fff;
}

.noUi-connects .noUi-connect {
    background-color: #A63322;
    /* Color for the dragged part */
}

.range_slider_min_max span img {
    margin-left: 46.5px;
}

.range_slider_min_max span img.first-element {
    margin-left: 0px;
}

.custom-slider {
    height: 12px;
    width: 460px;
    margin-bottom: 8px;
}

.custom-slider-icons {
    width: 472px;
    margin-left: 0px;
}

.noUi-horizontal .noUi-handle {
    width: 20px;
    height: 20px;
    top: -5px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -13px;
}

.noUi-handle:before,
.noUi-handle:after {
    content: none;
}

.range_slider_min_max {
    padding-left: 0px;
    padding-right: 0px;
}

.add-comparable-table .invalid-feedback {
    display: none !important;
}

.add-comparable-table .is-dirty.av-invalid,
.add-comparable-table .is-touched.av-invalid {
    border: 1px solid #f00;
}

.delete-comparable {
    top: 12px;
    position: relative;
    cursor: pointer;
}

.noUi-horizontal .noUi-tooltip {
    left: 202%;
    bottom: -21%;
    top: -36%;
}

.comparable-table-div .table thead th {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
}

.dispute-step-2 .noUi-target {
    margin: 2px 0 !important;
}

.comparable-table-div .add-comparable-table .custom-checkbox {
    margin-left: -14px;
    margin-top: -0px;
}

.comparable-table-div .add-comparable-table .custom-checkbox .custom-control-label {
    font-size: 12px;
}

.comparable-table-div .add-comparable-table .custom-checkbox .custom-control-label::before {
    width: 0.900rem;
    height: 0.900rem;
    left: -1.2rem;
}

.comparable-table-div .add-comparable-table .custom-checkbox .custom-control-label::after {
    left: -1.4rem;
    top: -1px;
}

.comparable-table-div .add-comparable-table .custom-control {
    min-height: 1.1rem;
}

.comparable-table-div .add-comparable-table .flag-div {
    position: relative;
    top: -2px;
}

.comparable-table-div .add-comparable-table .form-group {
    margin-bottom: 0.2rem;
}

.comparable-table-div .add-comparable-table hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.dispute-process-modal .dispute-step-2 .distance-text {
    margin-top: 9px;
}

.comparable-label-number {
    display: flex;
    height: 39px;
    align-items: center;
}

.dispute-process-modal .hr-color {
    border-color: #3ac6df;
}

.custom-confirm-body {
    min-width: 320px !important;
}

.custom-confirm-body p {
    text-align: center;
}

.custom-confirm-body .btn-danger {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.dispute-order-revise .row-even {
    background-color: rgba(0, 0, 0, 0.05);
    border-top: 1px solid #ccc;
    padding: 5px;
}

.dispute-order-revise .row-odd {
    background-color: #ffffff;
    border-top: 1px solid #ccc;
    padding: 5px;
}

.dispute-order-revise .label-column {
    font-weight: bold;
    width: 30%;
}

.dispute-order-revise .content-column {
    width: 70%;
}


.dispute-order-revise .myMapDiv {
    /* height: 520px; */
    margin-bottom: 0px;
}

.dispute-show-map-toggle.toggle-switch {
    width: 101px;
}

.dispute-show-map-toggle .toggle-switch-inner:before {
    padding-left: 8px;
}

.dispute-show-map-toggle .toggle-switch-inner:after {
    padding-right: 13px;
}

.dispute-show-map-toggle .toggle-switch-switch.un-checked {
    right: 66px;
}

.dispute-order-revise .reasons-checkbox {
    float: right;
    width: 94%;
    margin-top: 10px;
}

.reasons-checkbox label {
    width: 100%;
    float: left;
    padding: 2px;
}

.dispute-order-revise .other-reasons-checkbox-div {
    padding-left: 0px;
}
.condition-color {
    padding: 0px;
    border: 1px solid;
    border-radius: 100%;
        width: 28px;
}
.dispute-step1-table td {
    word-break: auto-phrase;
    padding: 4px !important;
}

.dispute-step1-table th {
    padding: 2px !important;
    letter-spacing: 0 !important;
}

.dispute-step1-table td.react-bs-table-no-data {
    display: table-cell !important;
}