.action-buttons {

    .dropdown-menu .dropdown-item>i,
    .dropdown-menu .dropdown-item>svg {
        margin-right: auto;
        font-size: small;
        vertical-align: 0px;
    }

    /* Style The Dropdown Button */
    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        z-index: 4;
        right: 0;
        padding: 0.3rem 0;
        margin: -33px 20px 0px 0px;
        font-size: 1rem;
        color: #525f7f;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 0 solid rgba(0, 0, 0, 0.15);
        border-radius: 0.4375rem;
        box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        width: max-content;
        padding: 5px 12px;
    }

    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {
        background-color: #f1f1f1
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: block;
    }
}