.neighborhood-land-user {

    .card-body {
        height: 580px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .budget-line-data.card-body {
        overflow: hidden;
    }

    .modal-header {
        padding: 10px;
        background: linear-gradient(87deg, #11cdef 50%, #1171ef 100%) !important;

        h5 {
            color: #fff;
        }
    }

    .close>span {
        color: #fff !important;
    }

    p span {
        color: #0ea6c9;
        font-weight: bold;
    }

    .property_type {
        font-weight: bold;

        span {
            text-transform: capitalize;
        }
    }

    .quality {
        span {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .map-marker-label {
        background-color: #000;
        color: #fff;
        width: 40px;
        border-radius: 4px;
        margin-top: -30px;
        font-size: 10px !important;
        padding: 2px;
    }


    .land-uses,
    .quality {
        .table-striped tbody tr:nth-of-type(odd) {
            background-color: rgba(21, 160, 191, 0.156) !important;
        }



        thead tr td {
            font-size: 14px;
            font-weight: 600;
            color: #525f7f;
            vertical-align: -webkit-baseline-middle;
        }

        tbody td {
            white-space: break-spaces !important;
            font-size: 13px !important;
        }

        thead tr {
            line-height: 20px !important;
            border-bottom: 2px solid #75bdcb !important;
        }

        .land-use-radio-btn {
            margin-bottom: -10px;
        }

        .land-use-radio-btn .yes-label {
            border: 1px solid #ef1111;
            padding: 0 6px;
            margin-right: 8px;
            cursor: pointer;
        }

        .land-use-radio-btn .no-label {
            border: 1px solid #11b3ef;
            padding: 0 6px;
            margin-right: 8px;
            cursor: pointer;
        }

        .land-use-radio-btn input:checked+label.yes-label {
            background-color: #ef1111;
            color: #fff;
        }

        .land-use-radio-btn input:checked+label.no-label {
            background-color: #11b3ef;
            color: #fff;
        }

        .quality_street-use-radio-btn .no-label {
            background: #11b3ef;
            border: 1px solid #11b3ef;
            padding: 0 6px;
            margin-right: 8px;
            color: #fff;
            cursor: pointer;
        }

        .quality_street-use-radio-btn .yes-label {
            border: 1px solid #ef1111;
            background: #ef1111;
            color: #fff;
            padding: 0 6px;
            margin-right: 8px;
            cursor: pointer;
        }
    }

    .street_view label.custom-control-label {
        font-size: 18px !important;
    }

    .street-view-image img {
        width: 100% !important;
    }

    span.street_view_date {
        float: right;
        position: absolute;
        bottom: 80px;
        z-index: 99999999;
        color: #fff;
        font-weight: bold;
        right: 30px;
    }

    .pdf-section {
        height: 600px;
        overflow: auto;
    }

    .condition-and-quality {


        .condition-quality-data {
            position: absolute;
            width: max-content;
        }

        th .condition-quality-header {
            margin: 0 0 0 10px;
            width: max-content;
        }

        tr {
            background-color: transparent !important;
        }

        .condition {
            display: grid;
            border: 1px solid #cdc4c4;
            width: 50px;
            // margin-left: 20px;
            cursor: pointer;

            div {
                border-bottom: 1px solid #cdc4c4;
            }

            img {
                padding: 12px;
            }

            .selected {
                background: #11cdef;
            }
        }

        .quality {
            display: grid;
            width: 90px;

            span {
                background: #998f8f;
                color: #fff;
                border-radius: 12px;
                padding: 4px 8px;
                font-weight: 700;
                cursor: pointer;
            }

            .selected {
                span {
                    background: #fff;
                    color: #11cdef;
                    border-radius: 12px;
                    padding: 4px 8px;
                }

                background: #11cdef;
            }

            div div {
                padding: 8px;
            }
        }
    }

    .budget-section {
        form h1 {
            font-size: 16px;
        }

        .react-bs-table {
            overflow: hidden;
            display: contents;
        }

        .budget-data {
            button {
                height: 24px;
            }

            i {
                font-size: 12px;
            }

            button i:hover {
                color: #fff;
            }
        }

        input.form-check-input {
            padding: 0;
            margin: -10px;
            top: 0;
            position: absolute;
            right: 12px;
        }

        .form-control {
            background: transparent !important;
        }

        .custom-checkbox {
            margin-bottom: -5px !important;
        }

        .form-group {
            margin-bottom: -5px !important;
        }

        .total-budget-td {
            vertical-align: middle;
        }


        input,
        select {
            height: 30px;
            // width: 80%;
        }

        label {
            font-size: 14px;
            font-weight: bold;
            padding: 6px;
            text-align: left;
            width: 100%;
        }

        .arv_data {
            label {
                padding: 0px;
            }

            span {
                font-size: 16px;
                color: #05a8c6;
            }
        }

        .budget-exhibit {

            input,
            select {
                height: 15px;
            }
        }
    }

    .budget-review-scrollbar {
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .total-text-container {
        display: flex;
        align-items: center;
    }

    .total-text-container span {
        margin: 0 10px;
    }

    .total-text-container .left {
        text-align: left;
    }

    .total-text-container .center {
        text-align: center;
    }

    .total-text-container .right {
        text-align: right;
    }

    .toggle-button {
        background-color: rgb(243, 241, 241);
        color: #333;
        /* Choose a suitable font color */
        padding: 10px;
        display: block;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .toggle-button.expanded {
        color: #333;
        /* Change the background color for the expanded state */
    }

    .upload-files-budget-review {
        padding: 5px;
        background-color: rgb(243, 241, 241);
    }

    .upload-files-budget-review #documents_upload {
        margin-left: 5px;
        margin-top: 7px;
    }

    .upload-files-budget-review .document-upload-div {
        margin-top: 6px;
        padding-left: 50%;
        display: contents;
    }

    .upload-files-budget-review .toggle-button {
        text-align: right;
        margin-left: -6%;
    }
}

.resume-select-option-modal {
    .form-control {
        padding: 0 !important;
    }
}


.map-toggle {
    text-align: right;
    margin-right: 10px;

    .toggle-switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 25px;
        margin-bottom: 0px;
    }

    .toggle-switch input[type="checkbox"] {
        display: none;
    }

    .toggle-switch .switch {
        position: absolute;
        cursor: pointer;
        background-color: #ccc;
        border-radius: 25px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: background-color 0.2s ease;
    }

    .toggle-switch .switch::before {
        position: absolute;
        content: "";
        left: 2px;
        top: 2px;
        width: 21px;
        height: 21px;
        background-color: #aaa;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    .toggle-switch input[type="checkbox"]:checked+.switch::before {
        transform: translateX(25px);
        background-color: #6699cc;
    }

    .toggle-switch input[type="checkbox"]:checked+.switch {
        background-color: #336699;
    }
}

.budget-line-item-edit-modal {
    .form-group {
        margin-bottom: 1.9rem;
    }

    .form-control-table-narrow label {
        margin-top: 8px;
    }
}

@media (max-width: 768px) {
    .neighborhood-land-user {

        .condition-and-quality {


            .condition-quality-data {
                position: absolute;
            }

            th .condition-quality-header {
                padding: 0px 50px 0px 0;
                // width: 100% !important;
                width: max-content !important;
                position: absolute;
                line-height: 0;

            }

        }
    }
}