.search-table .table td {
  padding: 8px;
}

.search-result .table-responsive {
  overflow-y: visible;
  overflow-x: visible;
  padding: 10px;
}

.tooltip-top>.tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
}

#tooltip-top>.tooltip-arrow {
  border-top: 5px solid #062e56;
}

.pagination {
  float: right;
}

.navbar-search .invalid-feedback {
  position: absolute;
  bottom: -22px;
  color: #fff;
}

.navbar-search-result-dark .invalid-feedback {
  position: absolute;
  bottom: -19px;
  color: black;
}

@media screen and (max-width: 768px) {
  .navbar-search-result-dark .invalid-feedback {
    position: absolute;
    bottom: -18px !important;
    color: black;
  }
}

@media screen and (max-width: 768px) {
  .navbar-search .invalid-feedback {
    position: absolute;
    bottom: -22px;
    color: black;
  }
}

.navbar-search .input-group-alternative {
  box-shadow: none;
}

/* .footer {
  padding-bottom: 30px;
}

.footer .copyright {
  position: fixed;
  bottom: 10px;
  width: 100%;
  background: #f0f4f7;
  padding: 1px 0px;
} */

.accordion__section .search-table .dropdown-menu {
  top: unset;
  bottom: 100% !important;
}

.drv-summary-table.drv-rows tbody tr:nth-child(n+2):not(:nth-last-child(-n+2)) {
  background-color: #b9ecf5;
  color: black;
}

.drv-summary-table.my-values-rows tbody tr:nth-child(n+2):not(:nth-last-child(-n+3)) {
  background-color: #b9ecf5;
  color: black;
}

.drv-summary-table td:nth-child(2) {
  font-weight: bold;
}